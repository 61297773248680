<template>
	<div class="business-address">
		<w-navTab titleText="选择地址"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="middle" v-for="(item, index) in addressList" @click="onSelect(index)">
				<div class="middle-top">
					<div>名称：{{ item.name }}</div>
					<div>电话：{{ item.telephone }}</div>
					<div>地址：{{ item.address }}</div>
				</div>
				<div class="middle-bottom">
					<div class="middle-bottom-left" @click.stop="selectDefault(index)">
						<van-checkbox v-model="item.id == defaultId" />
						<span>默认地址</span>
					</div>
					<div class="middle-bottom-right">
						<div @click.stop="updateAddress(index)">编辑</div>
						<div @click.stop="deleteAddress(index)">删除</div>
					</div>
				</div>
			</div>
			<div class="middle-btn" @click="$router.push({ name: 'BusinessAddressCreate' })">
				<van-icon name="add-o" size="24px" style="margin-right: 5px;" />
				添加新地址
			</div>
		</div>
		
	</div>
</template>

<script>
import Event from '@/store/event';
import VantVendor from '@/vendor/vant';
import BusinessApi from '@/api/business';
import LoadingPage from '@/components/LoadingPage';

import Business from '@/api/business';
export default {
	name: 'BusinessAddress',
	data() {
		return {
			defaultId: false,
			active: '',
			addressList: '',
			loadingPage: true
		};
	},
	created() {
		Business.myAddress()
			.then(result => {
				let addressList = result.data;

				// 标记其中一个默认地址
				for (let vo in addressList) {
					if (addressList[vo].is_default == 1) {
						this.defaultId = addressList[vo].id;
						break;
					}
				}

				this.addressList = addressList;
				this.loadingPage = false;
			})
			.catch(error => {
				console.log(error);
			});
	},
	methods: {
		onSelect(index) {
			Event.$emit('onSelectAddress', this.addressList[index]);
			this.$router.back();
		},
		updateAddress(index) {
			this.$router.push({
				name: 'BusinessAddressUpdate',
				params: { id: this.addressList[index].id }
			});
		},
		deleteAddress(index) {
			VantVendor.Dialog.confirm({
				title: '提示',
				message: '确认删除吗？'
			})
				.then(() => {
					Business.delAddress({
						address_id: this.addressList[index].id
					})
						.then(result => {
							Event.$emit('onDeleteAddress', this.addressList[index]);
							this.addressList.splice(index, 1);
						})
						.catch(error => {
							VantVendor.Toast.fail(error.msg);
						});
				})
				.catch(() => {
					console.log('用户选择取消');
				});
		},
		selectDefault(index) {
			let currentId = this.addressList[index].id;

			let params = {
				is_default: 1,
				address_id: currentId
			};

			BusinessApi.setDefaultAddress(params)
				.then(result => {
					// 切换至新的地址选中状态
					this.defaultId = currentId;
					VantVendor.Toast('设置成功');
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		}
	},
	components: {
		LoadingPage
	}
};
</script>

<style scoped lang="less">
.business-address {
	width: 100%;
	min-height: 100vh;
	background: #f2f2f2;
	box-sizing: border-box;
	padding-bottom: 80px;
	
	.container{
		padding: 10px;
		box-sizing: border-box;
		.middle {
			padding: 15px 10px;
			background-color: #fff;
			border-radius: 8px;
			margin-bottom: 10px;
			box-sizing: border-box;
			border: 2px solid #fff;
		
			&.active {
				border: 2px solid #3377ff;
			}
		
			.middle-top {
				font-size: 18px;
				line-height: 28px;
				color: #222;
				border-bottom: 1px solid #f2f2f2;
				padding-bottom: 10px;
			}
		
			.middle-bottom {
				font-size: 13px;
				line-height: 13px;
				color: #777;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-top: 11px;
		
				.middle-bottom-left {
					display: flex;
					align-items: center;
		
					span {
						margin-left: 5px;
					}
				}
				.middle-bottom-right {
					display: flex;
					justify-content: flex-start;
					div {
						margin-left: 15px;
					}
				}
			}
		}
		
		.middle-btn {
			width: 100%;
			background: #ffffff;
			border-radius: 8px;
			font-size: 18px;
			color: #222;
			text-align: center;
			padding: 12px 0;
			margin-top: 11px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
